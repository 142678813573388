import { Box, Button, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Portal, Skeleton, Stack, Table, TableCaption, Tbody, Td, Tfoot, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { FaCheck, FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import Space from "../Helpers/Space";
import Translation from "../Helpers/Translation";
import MyModal from "./Modal";

const HEADERS: { [key: string]: any } = {
  ['categories']: [
    'name',
    'description',
    'image'
  ],
  // ['sub-categories']: [
  //   'name',
  //   'description',
  //   'image',
  //   'category-slug',
  //   'active'
  // ],
  ['projects']: [
    'name',
    'description',
    'image',
    'categorySlug'
  ],
  ['generals']: [
    'name',
    'value'
  ],
  ['partners']: [
    'name',
    'image',
    'url'
  ],
};


type Props = {token: any}
type State = {selectedMenu: string, data: any, loading: boolean, isModalOpen: boolean, itemEdit: any, gridData: any}
export default class Grid extends React.Component<Props, State> {
  public toast: any;
  constructor(props: any) {
    super(props);

    this.state = {
      selectedMenu: 'categories',
      data: null,
      loading: true,
      isModalOpen: false,
      itemEdit: null,
      gridData: null
    }

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData(this.state.selectedMenu);

    console.log(HEADERS[this.state.selectedMenu]);
  }

  public setActiveMenu(menu: string) {
    this.setState({
      selectedMenu: menu,
      loading: true
    });

    console.log(menu);
    if (menu == 'sub-categories') {
      this.getDataFromTable('categories');
    }

    if (menu == 'projects') {
      this.getDataFromTable('sub-categories');
    }
    this.getData(menu);
  }

  public async getData(table: string) {
    /*try {
      
      const collection = firestore.collection(table);
      
      collection.orderBy('name', 'asc').get().then(snapshot => {
        this.setState({
          data: snapshot.docs,
          loading: false
        });
        console.log(this.state.data);
      });

    } catch (ex) {
      console.log(ex);
    }*/
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/${table}`)
    .then((data) => {
      console.log(data);
      this.setState({
        data: data.data,
        loading: false
      })
    }).catch((error) => {
      console.error(error);
    });

  }

  public async getDataFromTable(table: string) {
    // let collection = firestore.collection(table);
  
    // return collection.get().then((res: any) => {
    //   console.log(res.docs);
    //   this.setState({
    //     gridData: res.docs
    //   })
    // });

    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/categories`)
      .then((data) => {
        this.setState({
          gridData: data.data
        })
      }).catch((error) => {
        console.error(error);
      });
  }

  public async deleteData(item: any) {
    try {
      await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/${this.state.selectedMenu}/${item.id}`)
      .then((data) => {
      }).catch((error) => {
        console.error(error);
      });

      // item.ref.delete();
      this.getData(this.state.selectedMenu);
    } catch (ex) {
      console.log(ex);
    }
  }

  public addData() {
    // CustomModal("cancel", "new", "");
    this.setState({
      isModalOpen: true
    })
  }

  public closeModal() {
    this.setState({
      isModalOpen: false
    })
  }

  public render () {
    return (
      <>
        <MyModal open={this.state.isModalOpen} closeModal={() => {this.closeModal()}} selectedMenu={this.state.selectedMenu} onGetData={this.getData} itemEdit={this.state.itemEdit} gridData={this.state.gridData}/>
        <Box display="grid" alignItems="center" gridTemplateColumns="repeat(auto-fit, minmax(160px, 1fr))" gridGap="1rem">
          <Button colorScheme="gray" borderRadius={0} onClick={() => {this.setActiveMenu('categories')}} className={this.state.selectedMenu == 'categories' ? 'is-active' : ''}>
            {Translation.CATEGORIES}
          </Button>
          {/* <Button colorScheme="gray" borderRadius={0} onClick={() => {this.setActiveMenu('sub-categories')}} className={this.state.selectedMenu == 'sub-categories' ? 'is-active' : ''}>
            {Translation.SUBCATEGORIES}
          </Button> */}
          <Button colorScheme="gray" borderRadius={0} onClick={() => {this.setActiveMenu('projects')}} className={this.state.selectedMenu == 'projects' ? 'is-active' : ''}>
            {Translation.PROJECTS}
          </Button>
          <Button colorScheme="gray" borderRadius={0} onClick={() => {this.setActiveMenu('partners')}} className={this.state.selectedMenu == 'partners' ? 'is-active' : ''}>
            {Translation.PARTNERS}
          </Button>
          <Button colorScheme="gray" borderRadius={0} onClick={() => {this.setActiveMenu('generals')}} className={this.state.selectedMenu == 'generals' ? 'is-active' : ''}>
            {Translation.GENERAL}
          </Button>
        </Box>
        {this.state.selectedMenu != 'generals' &&
          <>
            <Space value="20px"/>
            <Box width="100%" textAlign="left">
              <Button colorScheme="green" borderRadius={0} leftIcon={<FaPlus />} onClick={() => {this.setState({itemEdit: null }); this.addData()}}>
                Δημιουργία
              </Button>
            </Box>
          </>
        }
        <Space />
        { !this.state.loading ?
          this.state.data &&
          <Table variant="simple">
            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
            <Thead>
              {
                <Tr>
                  <>
                  {HEADERS[this.state.selectedMenu].map((key: any, item: any) => {
                    return (
                      <Th>{key}</Th>
                    );
                  })}
                  </>
                  <Th colSpan={2} textAlign="center">Actions</Th>
              </Tr>
              }
            </Thead>
            <Tbody>
              {
                this.state.data.map((item: any, index: any) => {
                  return (
                  <Tr>
                    {HEADERS[this.state.selectedMenu].map((key: any, index: any) => {
                      return (
                        (key == 'active' ? (<Td>{item[key] == true ? <FaCheck /> : <></>}</Td>) : (<Td>{item[key]}</Td>))
                      );
                    })}
                    <Td>
                      <Button colorScheme="gray" borderRadius={0} onClick={() => { this.setState({itemEdit: item, isModalOpen: true})}}>
                        <FaEdit />
                      </Button>
                    </Td>
                    {this.state.selectedMenu != 'generals' &&
                      <Td>
                        <Popover>
                          <PopoverTrigger>
                            <Button><FaTrash /></Button>
                          </PopoverTrigger>
                          <Portal>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverHeader>{`Είστε σίγουρος για την διαγραφή του: "${item.name}"`}</PopoverHeader>
                              <PopoverCloseButton />
                              <PopoverBody>
                                <Button width="100%" colorScheme="blue" onClick={() => {this.deleteData(item)}}>Ναί</Button>
                              </PopoverBody>
                            </PopoverContent>
                          </Portal>
                        </Popover>
                      </Td>
                    }
                  </Tr>)
              })}
                
            </Tbody>
          </Table>
          :
          <>
          <Space />
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
          </>
        }
      </>
    )
  }
}