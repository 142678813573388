import Resizer from "react-image-file-resizer";
import axios from 'axios';

export const getNameFromSlug = async (slug: string) => {
  try {
    return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/categories/${slug}`)
    .then((data) => {
      return data.data;
    }).catch((error) => {
      console.error(error);
    });
  } catch (ex) {
    console.log(ex);
  }
}

export const getImage = async (image: string) => {
  try {
    return `${process.env.REACT_APP_API_STORAGE_ENDPOINT}/${image}`;
  } catch (ex) {
    console.log(ex);
  }
}

export const resizeFile = (file: any, hd: boolean = false) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      hd ? 2200 : 1200,
      hd ? 2200 : 1200,
      file.name.toLowerCase().split('.').pop() == "png" ? "PNG" : "JPEG",
      hd ? 85 : 70,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });