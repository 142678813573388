import { Box, Container, Heading, Skeleton, Stack } from "@chakra-ui/react";
import React from "react";
import BottomTextCard from "./BottomTextCard";
import Quote from "./Quote";
import Social from "./Social";
import Space from "./Helpers/Space";
import {
  NavLink
} from "react-router-dom";
import { Breadcrumbs } from "./Breadcrumbs";
import Gallery from 'react-grid-gallery';
import Footer from "./Footer";
import axios from "axios";
import ImageGallery from "./ImageGallery";
import Image from "react-graceful-image";

type Props = {params: any, collection: string, general: any};
type State = {data: any, mainImage: any, parentCategory: any, loading: boolean, images: any, linkedItems: any};

export default class ItemPage extends React.Component<Props, State> {

  constructor(props: any) {
    super(props);

    this.state = {
      data: null,
      mainImage: null,
      parentCategory: null,
      loading: true,
      images: [],
      linkedItems: null
    };

    this.getData = this.getData.bind(this);
    // this.getMainImage = this.getMainImage.bind(this);
    // this.getImages = this.getImages.bind(this);
    this.getLinkedItems = this.getLinkedItems.bind(this);
  }

  async componentDidMount() {
    await this.getData();
    // console.log(this.props.params);
  }

  componentWillUnmount() {
    // // fix Warning: Can't perform a React state update on an unmounted component
    // this.setState = (state,callback)=>{
    //     return;
    // };
  }

  public async getData() {
    // try {
      this.setState({
        loading: true,
      });

      axios.get(`${process.env.REACT_APP_API_ENDPOINT}/projects/${this.props.params.item}`)
        .then((data) => {
          this.setState({
            data: data.data,
            loading: false
          })

          if (this.state.data.categoryId !== 0) {
            this.getLinkedItems();
          }
        }).catch((error) => {
          console.error(error);
        });
  
    //   const items = firestore.collection(this.props.collection);
    //   items.where('slug', '==', this.props.params.item).get().then(async snapshot => {
    //     this.setState({
    //       data: snapshot.docs[0]
    //     });

    //     console.log(this.state);

    //     this.getMainImage();

    //     if (this.props.params.category != null) {
    //       this.setState({
    //         loading: true
    //       });
  
    //       let res = await getNameFromSlug(this.props.params.category);
    //       this.setState({
    //         parentCategory: res,
    //         loading: false
    //       });
    //     }

    //     await this.getImages();
    //     this.getLinkedItems();
    //   });
    // } catch (ex) {
    //   this.setState({
    //     loading: false
    //   });
    //   console.log(ex);
    // }
  }

  // public async getImages() {
  //   this.setState({
  //     loading: true
  //   });
  //   //Maybe change projects if another category is needed
  //   let listRef = await storage.ref().child(`${this.state.parentCategory != null ? `sub-categories/` : `projects/`}${this.state.data.slug}/images`);
  //   console.log(`${this.state.parentCategory != null ? `sub-categories/` : `projects/`}${this.state.data.slug}/images`);

  //   listRef.listAll().then((res) => {
  //       for (const itemRef of res.items) {
  //         itemRef.getDownloadURL().then((res) => {
  //           let imgs = [...this.state.images];
  //           imgs.push({
  //             src: res,
  //             thumbnail: res,
  //             thumbnailWidth: 500,
  //             thumbnailHeight: 300
  //           });

  //           this.setState({
  //             images: imgs,
  //             loading: false
  //           });
  //         });
  //       }
        
  //       // console.log(IMAGES);
  //       console.log(this.state.images);
  //     }).catch((error) => {
  //       console.log(error);
  //       this.setState({
  //         loading: false
  //       });
  //       // Uh-oh, an error occurred!
  //     });
  // }

  // public async getMainImage() {
  //   let img = await getImage(this.state.data.image);

  //   this.setState({
  //     mainImage: img,
  //     loading: false
  //   })
  // }

  public async getLinkedItems() {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/categories/get-by-id/${this.state.data.categoryId}`)
      .then((data) => {
        this.setState({
          linkedItems: [data.data],
        })

        // console.log(this.state.linkedItems)
      }).catch((error) => {
        console.error(error);
      });
  }

  public render() {
    return (
      <>
      {this.state.data &&
       <main>
        <Container maxW="container.xl" textAlign={'center'}>
          <Breadcrumbs parent={this.state.parentCategory} item={this.state.data} />
          <Space />
          {true ?
          <>
            <Image
              src={`${process.env.REACT_APP_API_STORAGE_ENDPOINT}/${this.state.data.image}`}
              width='100%'
              className={'h-600 object-fit-cover'}
              style={{objectPosition: 'center -100px'}}
            />
            <Box width={'100%'} height={'64px'} position={'relative'} top={'-64px'} transform={'scaleX(-1) rotate(180deg)'}>
              <svg id="svg-triangle-top" viewBox="0 0 200 15" fill={'#fff'} width={'100%'} height={'100%'} preserveAspectRatio="none"><polygon points="0,0  200,0 0,15"></polygon></svg>
            </Box>
            <Heading as="h2" className="heading-dot" fontSize={'56px'} margin={'0px auto'}>{this.state.data.name}</Heading>
            <Space />
            <Box>
              <Quote position={'center'} borderLeft={`0`} borderRight={`0`} paddingRight="0%" paddingLeft="0" text={this.state.data.description}/>
            </Box>
            <Space />
            <Box width="100%">
              <ImageGallery path={`projects/${this.state.data.slug}`} />
              {/* <Gallery images={this.state.images} height="100%" enableImageSelection={false} backdropClosesModal={true} /> */}
            </Box>
            {/* { this.state.linkedItems &&
              this.state.linkedItems.length > 0 &&
              <>
                <Space />
                <Heading as="h2" className="heading-dot" fontSize={'36px'} textAlign="left" margin={'0px auto'}>{this.state.parentCategory == null ? `Η κατηγορία` : 'Τα έργα μας' }</Heading>
                <Space />
                <NavLink exact={true} to={`/categories/${this.state.linkedItems[0]['category-slug']}/${this.state.linkedItems[0].slug}`}>
                  <BottomTextCard image={this.state.linkedItems[0].image} height={"300px"} text={this.state.linkedItems[0].name} description={this.state.linkedItems[0].description} />
                </NavLink>
                <Space />
              </>
            } */}
            {/* for category */}
            { this.state.linkedItems &&
              this.state.linkedItems.length > 0 &&
              <>
                <Space value="150px" />
                <Heading as="h2" className="heading-dot" fontSize={'36px'} textAlign="left" margin={'0px auto'}>{this.state.parentCategory == null ? `Η κατηγορία` : 'Τα έργα μας' }</Heading>
                <Space value="20px" />
                <Box display="grid" alignItems="center" gridTemplateColumns="repeat(auto-fit, minmax(auto, 1fr))" gridGap="1rem">
                  {this.state.linkedItems.map((item: any, index: any) => {
                    // console.log(item)
                    return (
                    <NavLink exact={true} to={`/categories/${item.slug}`}>
                      <BottomTextCard image={item.image} height={"300px"} text={item.name} description={item.description} />
                    </NavLink>
                    )
                  })}
                </Box>
              </>
            }
            <Space value="100px"/>
            <Social general={this.props.general} />
          </> : 
          <>
          <Space />
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
          </>
          }
        </Container>
        <Footer general={this.props.general}/>
      </main>
      }
      </>
    )
  }
}