import React from "react";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import Translation from "./Helpers/Translation";
import {
  NavLink
} from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import Colors from "../static/Colors";

export const Breadcrumbs = ({parent, item}: any) => {

  let DynamicCategoryBreadcrumb = ({ match }: any) => {
    if (parent == null) {
      return '';
    }

    return (`${(parent.name)}`);
  };

  let DynamicItemBreadcrumb = ({ match }: any) => {
    if (item == null) {
      return '';
    }

    return (`${(item.name)}`);
  };

  let routes = [
    { path: '/categories/:category/:item', breadcrumb: DynamicItemBreadcrumb },
    { path: '/categories/:category', breadcrumb: DynamicCategoryBreadcrumb },
    { path: '/categories', breadcrumb: Translation.CATEGORIES },
    { path: '/projects/:project', breadcrumb: DynamicItemBreadcrumb },
    { path: '/projects', breadcrumb: Translation.PROJECTS },
    { path: '/', breadcrumb: Translation.HOME },
  ];

  const breadcrumbs = useBreadcrumbs(routes);
  return (
    <>
    <Breadcrumb width="100%" textAlign="left" color={Colors.PRIMARY} padding="5% 0 0 10%">
        {breadcrumbs.map(({
          match,
          breadcrumb
        }, index: number) => (
          <BreadcrumbItem key={index} {...index == breadcrumbs.length ? 'isCurrentPage' : ''}>
          <span key={match.url}>
            <BreadcrumbLink as={NavLink} to={match.url}>{breadcrumb}</BreadcrumbLink>
          </span>
          </BreadcrumbItem>
        ))}
        
      </Breadcrumb>
    </>
  );
};