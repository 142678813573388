import { Box, Heading, Text, Container } from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { getImage } from "../Firebase/dbHelper";
import Colors from "../static/Colors";
import GeneralSetting from "./Helpers/GeneralSetting";
import Space from "./Helpers/Space";
import Image from "react-graceful-image";

type Props = {general: any}
type State = {images: any, loading: boolean, width: number}
export default class Slider extends React.Component <Props, State> {
  public constructor(props: any) {
    super(props);

    this.state = {
      images: null,
      loading: true,
      width: 0
    }

    this.setImages = this.setImages.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  public async setImages() {
     axios.get(`${process.env.REACT_APP_API_ENDPOINT}/categories/get-slider-data`)
    // axios.get(`http://localhost/vikatosshading.gr/public/api/projects/get-slider-data`)
      .then((data) => {
        let imgs: any = [];

        for (const i in data.data) {
        // data.data.map((d: any, index: any) => {
          let d = data.data[i];
          let image: any = {};
          let imgUrl = `${process.env.REACT_APP_API_STORAGE_ENDPOINT}/${d.image}`;
          // let imgUrl = `http://localhost/vikatosshading.gr/storage/app/public/${d.image}`;
          image['href'] = `${d.href}`;
          image['original'] = imgUrl;
          image['thumbnail'] = imgUrl;
          image['text'] = "";
          image['position'] = d.position ? d.position : 'center top';
          imgs.push(image);
        // });
        }

        this.setState({
          images: imgs,
          loading: false
        });

        // console.log(imgs);
      }).catch((error) => {
        console.error(error);
      });
  }

  public async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    await this.setImages();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  public render() {
    return (
      this.state.images &&
        <>
          <Container maxW="container.xl" textAlign={'center'}>
            {!this.state.loading ?
            <>
            <Box width={'100%'}>
              <ImageGallery items={this.state.images} showThumbnails={false} showFullscreenButton={false} height={'700px'} showPlayButton={false} autoPlay={false} renderItem={(renderItem: any) => {
                return (
                  <a href={renderItem.href}>
                    <Image
                      src={renderItem.original}
                      width='100%'
                      height='700'
                      className={'top-nav-image object-fit-cover'}
                      style={{objectPosition: renderItem.position && this.state.width > 785 ? renderItem.position : '0 top'}}
                    />
                    { (renderItem['text'] != null && renderItem['text'] != '') &&
                      <Box width={'60%'} maxWidth={'60%'} margin={'0 auto'} className={'centeredSliderText'} whiteSpace="pre-wrap">
                        <Box width={['100%', '100%', '100%', '50%']} style={{backgroundColor: 'rgba(0, 0, 0, .2)'}} padding={'15px 5px'} margin={'0 auto'}>
                        <Text className="heading-dot"  textShadow={`2px 2px 5px ${Colors.DARK}`} lineHeight="normal" color={Colors.WHITE} fontSize={'3.5vh'} margin={'0px auto'}>{renderItem['text']}</Text>
                      </Box>
                      </Box>
                    }
                  </a>)
              }} />
            </Box>
            <Box width={'100%'} height={'64px'} position={'relative'} top={'-64px'} transform={'scaleX(-1) rotate(180deg)'}>
              <svg id="svg-triangle-top" viewBox="0 0 200 15" fill={'#fff'} width={'100%'} height={'100%'} preserveAspectRatio="none"><polygon points="0,0  200,0 0,15"></polygon></svg>
            </Box>
            </>
            :
            <Space value="700px" />
            
            }
          </Container>
        </>
    )
  }
}