import { Box, Container, Heading, Skeleton, Stack } from "@chakra-ui/react";
import React from "react";
import BottomTextCard from "./BottomTextCard";
import Quote from "./Quote";
import Social from "./Social";
import ImageGallery from "./ImageGallery";
import Space from "./Helpers/Space";
import {
  NavLink
} from "react-router-dom";
import { Breadcrumbs } from "./Breadcrumbs";
import { getImage, getNameFromSlug } from "../Firebase/dbHelper";
import Footer from "./Footer";
import axios from "axios";
import Image from "react-graceful-image";

type Props = {params: any, general: any};
type State = {data: any, category: any, loading: boolean, linkedItems: any};

export default class CategoriesPage extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      data: null,
      category: null,
      loading: true,
      linkedItems: null
    };

    // console.log(this.props.params);
  }

  async componentDidMount() {
    // try {
      // if (snapshot.empty) {
      //   console.log('No matching documents.');
      //   return;

      
      // }
      if (this.props.params.category != null) {
        this.setState({
          loading: true
        });

        let category = await getNameFromSlug(this.props.params.category);
        console.log(category);
        this.setState({
          category: category,
          loading: false
        })

        this.getLinkedItems();
      }
      await this.getData();
  }

  public async getData() {
    try {
      this.setState({
        loading: true
      });
  
      if (this.props.params.category != null) {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/subcategories`)
        // axios.get(`http://localhost/vikatosshading.gr/public/api/subcategories`)
        .then((data) => {
          this.setState({
            data: data.data,
            loading: false
          })
        }).catch((error) => {
          console.error(error);
        });
      } else {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/categories`)
        // axios.get(`http://localhost/vikatosshading.gr/public/api/categories`)
        .then((data) => {
          this.setState({
            data: data.data,
            loading: false
          })
        }).catch((error) => {
          console.error(error);
        });
      }

      
      
    } catch (ex) {
      console.log(ex);
    }
  }

  public async getLinkedItems() {
    if (this.state.category != null) {
      axios.get(`${process.env.REACT_APP_API_ENDPOINT}/projects/get-by-category-id/${this.state.category.id}`)
        .then((data) => {
          this.setState({
            linkedItems: data.data,
          })

          // console.log(this.state.linkedItems)
        }).catch((error) => {
          console.error(error);
        });
    }
  }

  public render() {
    return (
      <>
       <main>
        <Container maxW="container.xl" textAlign={'center'}>
          <Breadcrumbs parent={this.state.category} item={null} />
          <Space />
          {this.state.data && !this.state.loading ?
          <>
            {this.props.params.category != null &&
            <>
            <Image
              src={`${process.env.REACT_APP_API_STORAGE_ENDPOINT}/${this.state.category ? this.state.category.image : ''}`}
              width='100%'
              className={'h-600 object-fit-cover'}
              style={{objectPosition: 'center -100px'}}
            />
            <Box width={'100%'} height={'64px'} position={'relative'} top={'-64px'} transform={'scaleX(-1) rotate(180deg)'}>
              <svg id="svg-triangle-top" viewBox="0 0 200 15" fill={'#fff'} width={'100%'} height={'100%'} preserveAspectRatio="none"><polygon points="0,0  200,0 0,15"></polygon></svg>
            </Box>
            </>
            }
            <Heading as="h2" className="heading-dot" fontSize={'56px'} margin={'0px auto'}>{this.state.category ? this.state.category.name : 'Όλες οι Κατηγορίες'}</Heading>
            <Space />
            <Box>
              <Quote position={'center'} borderLeft={`0`} borderRight={`0`} paddingRight="0%" paddingLeft="0" text={this.state.category ? this.state.category.description : ''}/>
            </Box>
            {/* <Image
              src={`api/backend/storage/${this.state.data.image}`}
              width='100%'
              className={'h-400 object-fit-cover'}
            /> */}
            <Space/>
            <Box display="grid" alignItems="center" gridTemplateColumns="repeat(auto-fit, minmax(240px, 1fr))" gridGap="1rem">
              {this.state.data.map((item: any, index: number) => {
                return (
                  <NavLink exact={true} to={`/categories/${this.state.category ? `${this.state.category.slug}/` : ``}${item.slug}`} key={index} onClick={() => {this.getData()}} style={{height: '100%'}}>
                    <BottomTextCard image={item.image} height={"300px"} text={item.name} />
                  </NavLink>
                )
              })}
            </Box>
            {/* <Box width="100%">
              <Gallery images={this.state.images} height="100%" enableImageSelection={false} backdropClosesModal={true} />
            </Box> */}
            <ImageGallery path={`categories/${this.props.params.category}`} />
            <Space />

            { this.state.linkedItems &&
              this.state.linkedItems.length > 0 &&
              <>
                <Space value="150px" />
                <Heading as="h2" className="heading-dot" fontSize={'36px'} textAlign="left" margin={'0px auto'}>{'Τα έργα μας'}</Heading>
                <Space value="20px" />
                <Box display="grid" alignItems="center" gridTemplateColumns="repeat(auto-fit, minmax(auto, 1fr))" gridGap="1rem">
                  {this.state.linkedItems.map((item: any, index: any) => {
                    // console.log(item)
                    return (
                    <NavLink exact={true} to={`/projects/${item.slug}`}>
                      <BottomTextCard image={item.image} height={"300px"} text={item.name} description={item.description} />
                    </NavLink>
                    )
                  })}
                </Box>
              </>
            }
            <Space value="100px"/>
            <Social general={this.props.general} />
          </> : 
          <>
          <Space />
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
          </>
          }
        </Container>
        <Footer general={this.props.general}/>
      </main>
      </>
    )
  }
}