import { Box, Container, Flex, Grid, Heading, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import Colors from "../static/Colors";
import BottomTextCard from "./BottomTextCard";
import Quote from "./Quote";
import Social from "./Social";
import Space from "./Helpers/Space";
import Companies from "./Companies";
import Slider from "./Slider";
import Projects from "./Projects";
import {
  NavLink
} from "react-router-dom";
import GeneralSetting from "./Helpers/GeneralSetting";
import Footer from "./Footer";
import { timeStamp } from "console";
import axios from "axios";

type Props = {categories: any, general: any, partners: any};
type State = {projects: any, loading: boolean};

export default class HomePage extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      projects: null,
      loading: true
    }

    this.getProjects = this.getProjects.bind(this);
  }
  
  async componentDidMount() {
    await this.getProjects();
  }

  public async getProjects() {
      this.setState({
        loading: true
      });
  
      axios.get(`${process.env.REACT_APP_API_ENDPOINT}/projects`)
      // axios.get(`http://localhost/vikatosshading.gr/public/api/projects`)
      .then((data) => {
        this.setState({
          projects: data.data,
          loading: false
        })
      }).catch((error) => {
        console.error(error);
      });
  }


  public render() {
    return (
      !this.state.loading && this.props.categories &&
      <>
        <main>
          <Slider general={this.props.general} />
          <Container maxW="container.xl" textAlign={'center'}>
            <Space />
             <Box>
              <Quote position={'left'} borderRight={`0`} borderLeft={`4px solid ${Colors.PRIMARY}`} paddingLeft="10%" paddingRight="0" text={GeneralSetting.value(this.props.general, 'description-in-starting-page')}/>
            </Box>
            
            {/* <Quote position={'left'} borderLeft={`4px solid ${Colors.PRIMARY_COLOR}`} borderRight="0" paddingLeft="10%" paddingRight="0" text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lacus vestibulum sed arcu non odio euismod. Massa sapien faucibus et molestie ac feugiat. Et netus et malesuada fames ac turpis egestas. Consequat id porta nibh venenatis cras sed. Feugiat in ante metus dictum at. Pretium quam vulputate dignissim suspendisse in est ante in. Auctor urna nunc id cursus metus. Vestibulum lectus mauris ultrices eros in cursus turpis. Neque egestas congue quisque egestas diam in arcu cursus euismod. Tortor posuere ac ut consequat. Enim diam vulputate ut pharetra sit amet aliquam id.'}/> */}
            <Space value="100px"/>
            { this.props.categories && 
              <>
                <Heading as="h2" fontSize={'56px'} margin={'0px auto'}>Κατηγορίες</Heading>
                <Space />
                <Box display="grid" alignItems="center" gridTemplateColumns="repeat(auto-fit, minmax(240px, 1fr))" gridGap="1rem">
                  {this.props.categories.map((item: any, index: any) => {
                    return (<NavLink exact={true} to={`/categories/${item.slug}`} style={{height: '100%'}}>
                      <BottomTextCard image={item.image} height={"300px"} text={item.name} />
                    </NavLink>)
                  })}
                </Box>
              </>
            }
            <Space value="100px"/>
            <Heading as="h2" className="heading-dot" fontSize={'56px'} margin={'0px auto'}>Εταιρίες που συνεργαζόμαστε</Heading>
            <Space />
            <Box display="grid" alignItems="center" grid justifyContent="center" gridTemplateColumns="repeat(3, auto)" gridGap="2rem" width={'100%'}>
              <Companies companies={this.props.partners}/>
            </Box>
            <Space value="150px"/>
            <Projects projects={this.state.projects}/>
            <Space />
            <Social general={this.props.general}/>
          </Container>
          <Footer general={this.props.general}/>
        </main>
      </>
    )
  }
}