import { Box, Container } from "@chakra-ui/react";
import React from "react";
import Footer from "./Footer";
import GeneralSetting from "./Helpers/GeneralSetting";
import { getImage } from "../Firebase/dbHelper";
import Quote from "./Quote";
import Colors from "../static/Colors";
import Space from "./Helpers/Space";
import Social from "./Social";
import Image from "react-graceful-image";

type Props = {general: any};
type State = {data: any};
export default class About extends React.Component <Props, State> {
  public constructor(props: any) {
    super(props);

    this.state = {
      data: null
    }
  }

  public async componentDidMount() {
    let d = GeneralSetting.data(this.props.general, 'about_us');
    d = d.find((a: any) => a != undefined);
    let data: any = {};
    data.description = d.description;
    data.image = await getImage(d.value);

    this.setState({
      data: data
    })
  }



  public render() {
    return (
    this.state.data &&
    <>
      <Container maxW="container.xl" textAlign={'center'}>
        <Image
          src={`${this.state.data.image}`}
          width='100%'
          className={'h-600 object-fit-cover'}
        />
        <Space />
        <Box>
          <Quote position={'left'} borderRight={`0`} borderLeft={`0`} paddingLeft="0" paddingRight="0" text={this.state.data.description}/>
        </Box>
        <Space value="100px" />
        <Social general={this.props.general}/>
      </Container>
      <Footer general={this.props.general}/>
    </>)
  }
}