import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Container, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import React from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  NavLink
} from "react-router-dom";
import { FaAlignJustify, FaPhone } from "react-icons/fa";
import GeneralSetting from "./Helpers/GeneralSetting";
import Colors from "../static/Colors";
import Image from "react-graceful-image";

type Props = {categories: any, general: any};
type State = {};

export default class Header extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <>
      <Box style={{position: 'fixed', zIndex: 10, backgroundColor: Colors.WHITE, width: '100%'}}>
        <Box className="top-nav-bar" borderBottom={'solid 1px #eeeeee'}>
          <Container maxW="container.xl" style={{position: 'relative'}}>
          <Box textAlign={'center'} display={'flex'} justifyContent={'center'} width={'100%'}>
            <a href="/">
              <Image
                src={`${process.env.PUBLIC_URL}/logo-black.svg`}
                width='64px'
                height='64px'
              />
            </a>
            <span style={{textAlign: 'right', display: 'inline-block', position: 'absolute', right: '15px', top: '20px'}}>
              <span style={{display: 'inline-block', marginRight: '10px'}}><FaPhone /></span> {GeneralSetting.value(this.props.general, 'phone-on-top')}
            </span>
          </Box>
          </Container>
        </Box>
        <Container maxW="container.xl">
          <Box display={['block', 'block', 'none', 'none']}>
            <Accordion allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Menu>
                          <MenuButton
                            height={'64px'}
                            width={'64px'}
                            as={IconButton}
                            aria-label="Options"
                            className={'phone-btn'}
                            border={'0'}
                            bgColor={'transparent'}
                            display={['block', 'block', 'none', 'none']}
                            icon={<FaAlignJustify />}
                          />
                        </Menu>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Menu isLazy>
                    <NavLink exact={true} activeClassName='is-active' to="/" className="chakra-button chakra-menu__menu-button top-nav-item css-1smgvs3 w-100" height={'64px'} position={'relative'} flex={'1 1 0px'}>
                      <Text>Αρχικη</Text>
                    </NavLink>
                    <NavLink exact={true} activeClassName='is-active' to="/categories" className="chakra-button chakra-menu__menu-button top-nav-item css-1smgvs3 w-100" height={'64px'} position={'relative'} flex={'1 1 0px'}>
                      <Text>Κατηγοριες</Text>
                    </NavLink>
                    <NavLink exact={true} activeClassName='is-active' to="/projects" className="chakra-button chakra-menu__menu-button top-nav-item css-1smgvs3 w-100" height={'64px'} position={'relative'} flex={'1 1 0px'}>
                      <Text>Οι Δουλειες μας</Text>
                    </NavLink>
                    <NavLink exact={true} activeClassName='is-active' to="/about" className="chakra-button chakra-menu__menu-button top-nav-item css-1smgvs3 w-100" height={'64px'} position={'relative'} flex={'1 1 0px'}>
                      <Text>Σχετικα με εμας</Text>
                    </NavLink>
                  </Menu>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
          <Box display={['none', 'none', 'flex', 'flex']} justifyContent={'space-evenly'}>
            <Menu isLazy>
              <NavLink exact={true} activeClassName='is-active' to="/" className="chakra-button chakra-menu__menu-button top-nav-item css-1smgvs3" height={'64px'} position={'relative'} flex={'1 1 0px'}>
                <Text>Αρχικη</Text>
              </NavLink>
            </Menu>
            {/* <Menu isLazy>
              <NavLink exact={true} activeClassName='is-active' to="/categories" className="chakra-button chakra-menu__menu-button top-nav-item css-1smgvs3" height={'64px'} position={'relative'} flex={'1 1 0px'}>
                <Text>Κατηγοριες</Text>
              </NavLink>
            </Menu> */}
            <Menu isLazy>
              <MenuButton as={Button} className={'top-nav-item'}  height={'64px'} position={'relative'} flex={'1 1 0px'} rightIcon={<ChevronDownIcon />}>
                Κατηγοριες
              </MenuButton>
              <MenuList borderRadius={"0px"} border={'0'}>
                <NavLink exact={true} to={`/categories`} activeClassName='is-active'>
                  <MenuItem>Όλες οι Κατηγορίες</MenuItem>
                </NavLink>
                {this.props.categories.map((item: any, index: any) => {
                  return (<NavLink exact={true} to={`/categories/${item.slug}`} activeClassName='is-active'>
                    <MenuItem key={`cat-${index}`}>{item.name}</MenuItem>
                  </NavLink>)
                })}
              </MenuList>
            </Menu>
            <Menu isLazy>
              <NavLink exact={true} activeClassName='is-active' to="/projects" className="chakra-button chakra-menu__menu-button top-nav-item css-1smgvs3" height={'64px'} position={'relative'} flex={'1 1 0px'}>
                <Text>Οι Δουλειες μας</Text>
              </NavLink>
            </Menu>
            <Menu isLazy>
              <NavLink exact={true} activeClassName='is-active' to="/about" className="chakra-button chakra-menu__menu-button top-nav-item css-1smgvs3" height={'64px'} position={'relative'} flex={'1 1 0px'}>
                <Text>Σχετικα με εμας</Text>
              </NavLink>
            </Menu>
          </Box>
        </Container>
        </Box>
      </>
    );
  }
}