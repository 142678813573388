import { Box, Heading, Link } from "@chakra-ui/react";
import React from "react";
import { getImage } from "../Firebase/dbHelper";
import GeneralSetting from "./Helpers/GeneralSetting";
import Image from "react-graceful-image";

type Props = {companies: any}
type State = {}
export default class Companies extends React.Component<Props, State> {
  public render() {
    return (
      <>
        {this.props.companies.map((item: any, index: number) => {
          return <Company item={item} />
        })}
      </>
    )
  }
}

type PropsCompany = {item: any}
type StateCompany = {image: any}
class Company extends React.Component <PropsCompany, StateCompany> {
  constructor(props: any) {
    super(props);

    this.state = {
      image: null
    }
  }

  async componentDidMount() {
    let img = await getImage(this.props.item.image);

    this.setState({
      image: img
    })
  }

  public render() {
    // const url: any = GeneralSetting.value(this.props.item, 'url');
    // let u = url.find((a: any) => a != undefined);
    // console.log(this.props.item);
    return (
      <>
        {this.state.image &&
          <Box margin="5px 5px 30px" maxWidth="150px" height={'auto'}>
            <Box position="relative" width="100%" height="100%" overflow="hidden">
              <Link href={this.props.item.url} isExternal>
                <Image
                  src={this.state.image}
                  width='100%'
                  className="grid-img"
                />
              </Link>
            </Box>
          </Box>
        }
      </>
    )
  }
}