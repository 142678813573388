import { ColorModeScript, Image } from "@chakra-ui/react"
import * as React from "react"
import ReactDOM from "react-dom"
import App from "./App"

ReactDOM.render(
  // <React.StrictMode>
    <React.Suspense
      fallback={<div><Image
                src={`${process.env.PUBLIC_URL}/logo-black.svg`}
                width='64px'
                height='64px'
              /></div>}>
      <ColorModeScript />
      <App />
    </React.Suspense>
  // </React.StrictMode>
  ,
  document.getElementById("root"),
)




/*
ReactDOM.render(
  <React.StrictMode>
    
      <ColorModeScript />
      <MainApp />
    
  </React.StrictMode>
  ,
  document.getElementById("root"),
)

function MainApp() {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  return (
    <div className="Main" ref={wrapperRef}>
      {!imagesLoaded ? "Loading images..." : <><React.Suspense
      fallback={<div><Image
                src={`${process.env.PUBLIC_URL}/logo-black.svg`}
                width='64px'
                height='64px'
              /></div>}><App /></React.Suspense></>}
      
    </div>
    
  )
}*/