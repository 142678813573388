export default class GeneralSetting {
  public static value(general: any, setting: string): string {
    return general.map((item: any, index: any) => {
      // console.log(item);
      if (item.key == setting) {
        return item.value;
      }
    });
  }

  public static data(general: any, setting: string): any {
    return general.map((item: any, index: any) => {
      // console.log(item);
      if (item.key == setting) {
        return item;
      }
    });
  }
}