import { Checkbox, FormControl, FormHelperText, FormLabel, Input, Select, Textarea, Image, Button, Heading, Box, GridItem, Grid as Grd } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaCertificate, FaTrash } from "react-icons/fa";
import Space from "../../Helpers/Space";

export function CategoryForm({item, uploadMainImage, uploadImages}: any) {
  // console.log(item);
  const [mainImage, setMainImage] = useState('');
  const [images, setImages]: any = useState(null);
  useEffect(() => {
    if (item != null) {
      if (item.image != null) {
        setMainImage(item.image);
      }
      if (item.slug != null) {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/storage/categories/${item.slug}`).then((data) => {
          console.log(data.data);
          setImages(data.data);
        })
      }
    }
  }, []);

  const uploadFunc = async (e: any) => {
    if (item.slug != null) {
      await uploadImages(e);
      axios.get(`${process.env.REACT_APP_API_ENDPOINT}/storage/categories/${item.slug}`).then((data) => {
        setImages(data.data);
      })
    }
  }

  return (
    <>
      {CreateInput('name', 'text', 'Όνομα', 'Το όνομα της κατηγορίας.', item ? item.name : null)}
      <Space value="20px" />
      {CreateTextarea('description', 'Περιγραφή', 'Η περιγραφή της κατηγορίας.', item ? item.description : null)}
      <Space value="20px" />
      {item != null &&
        <>
          {CreateImageInput('values', 'file', 'Φωτογραφίες', 'Οι φωτογραφίές της κατηγορίας.', null, uploadFunc, false, {multiple: 'multiple'})}
          <Space value="20px" />
        </>
      }
      {CreateInput('priority', 'number', 'Προτεραιότητα', 'Η προτεραιότητα της κατηγορίας, χρησιμοποιήστε αρνητική τιμή αν θέλετε να πάει στο τέλος.', item ? item.priority : 0)}
      {/* <Space value="20px" />
      {CreateCheckbox('active', 'Ενεργό', 'Αν είναι ενεργή η κατηγορία.', item ? item.active : false)} */}

      {images &&
      <>
      <h4>Images: </h4>
      {images.map((img: any, i: any) => {
        return (
          <>
          <Space value="10px" />
          <Image
            src={`${process.env.REACT_APP_API_STORAGE_ENDPOINT}/categories/${item.slug}/images/${img}`}
            width='100%'
          />

          <Grd templateColumns='repeat(2, 1fr)' gap={4}>
            <GridItem colSpan={2} h='10'>
              <>
              <Button colorScheme='blue' size='sm' float={'left'} marginTop={'-50px'} disabled={mainImage === `categories/${item.slug}/images/${img}`} position={'relative'} left={'15px'} onClick={async () => {
                const data = new FormData();
                data.append('path', `categories/${item.slug}/images/${img}`);
                data.append('selectedMenu', 'categories');
                data.append('id', item.id);
                await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/categories/set-main-image`,
                  data
                )
                .then((d) => {
                  if (item.slug != null) {
                    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/categories/get-by-id/${item.id}`).then((data) => {
                      console.log(data.data);
                      setMainImage(data.data.image);
                      // item.image = data.data.image;
                    })
                  }
                }).catch((error) => {
                });
              }}>
                <FaCertificate /> {mainImage !== `categories/${item.slug}/images/${img}` ? 'Set as Main' : 'MAIN IMAGE'}
              </Button>
              </>
            </GridItem>
            <GridItem colStart={4} colEnd={6} h='10'>
              <Button colorScheme='red' size='sm' float={'right'} marginTop={'-50px'} position={'relative'} left={'-15px'} onClick={async () => {
                const data = new FormData();
                data.append('path', `categories/${item.slug}/images/${img}`);
                data.append('selectedMenu', 'categories');
                data.append('id', item.id);
                await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/storage/delete-image`,
                  data
                )
                .then((d) => {
                  if (item.slug != null) {
                    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/storage/categories/${item.slug}`).then((data) => {
                      console.log(data.data);
                      setImages(data.data);
                    })
                  }
                }).catch((error) => {
                });
              }}>
                <FaTrash /> Delete
              </Button>
            </GridItem>
          </Grd>
          </>
        )
      })}
      
      </>
      }
    </>
  )
}

export function SubCategoryForm({item, categories}: any) {
  
  return (
    <>
      {CreateSelect('category-slug', 'Κατηγορία', 'Η κατηγορία που ανήκει.', item ? item['category-slug'] : null, categories)}
      <Space value="20px" />
      {CreateInput('name', 'text', 'Όνομα', 'Το όνομα της υπο-κατηγορίας.', item ? item.name : null)}
      <Space value="20px" />
      {CreateTextarea('description', 'Περιγραφή', 'Η περιγραφή της υπο-κατηγορίας.', item ? item.description : null)}
      <Space value="20px" />
      {CreateInput('image', 'file', 'Φωτογραφία', 'Η φωτογραφία της υπο-κατηγορίας.', null)}
      {/* <Space value="20px" />
      {CreateCheckbox('active', 'Ενεργό', 'Αν είναι ενεργή η υπο-κατηγορία.', item ? item.active : false)} */}
    </>
  )
}

export function ProjectsForm({item, categories, uploadImages}: any) {
  const [mainImage, setMainImage] = useState('');
  const [images, setImages]: any = useState(null);
  useEffect(() => {
    if (item != null) {
      if (item.image != null) {
        setMainImage(item.image);
      }
      if (item.slug != null) {
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}/storage/projects/${item.slug}`).then((data) => {
          console.log(data.data);
          setImages(data.data);
        })
      }
    }
    
  }, []);

  const uploadFunc = async (e: any) => {
    if (item.slug != null) {
      await uploadImages(e);
      axios.get(`${process.env.REACT_APP_API_ENDPOINT}/storage/projects/${item.slug}`).then((data) => {
        setImages(data.data);
      })
    }
  }

  return (
    <>
      {CreateSelect('categoryId', 'Κατηγορία', 'Η κατηγορία που ανήκει.', item ? item['categoryId'] : null, categories)}
      <Space value="20px" />
      {CreateInput('name', 'text', 'Όνομα', 'Το όνομα της δουλειάς.', item ? item.name : null)}
      <Space value="20px" />
      {CreateTextarea('description', 'Περιγραφή', 'Η περιγραφή της δουλειάς.', item ? item.description : null)}
       {item != null &&
        <>
          <Space value="20px" />
          {CreateImageInput('values', 'file', 'Φωτογραφίες', 'Οι φωτογραφίές της δουλειάς.', null, uploadFunc, false, {multiple: 'multiple'})}
        </>
      }
      {/* {CreateInput('image', 'file', 'Φωτογραφία', 'Η φωτογραφία της δουλειάς.', null)} */}
      {/* <Space value="20px" />
      {CreateCheckbox('active', 'Ενεργό', 'Αν είναι ενεργή η δουλειά.', item ? item.active : false)} */}

      {images &&
      <>
      <h4>Images: </h4>
      {images.map((img: any, i: any) => {
        return (
          <>
          <Space value="10px" />
          <Image
            src={`${process.env.REACT_APP_API_STORAGE_ENDPOINT}/projects/${item.slug}/images/${img}`}
            width='100%'
          />
          <Grd templateColumns='repeat(2, 1fr)' gap={4}>
            <GridItem colSpan={2} h='10'>
              <>
              <Button colorScheme='blue' size='sm' float={'left'} marginTop={'-50px'} disabled={mainImage === `projects/${item.slug}/images/${img}`} position={'relative'} left={'15px'} onClick={async () => {
                const data = new FormData();
                data.append('path', `projects/${item.slug}/images/${img}`);
                data.append('selectedMenu', 'projects');
                data.append('id', item.id);
                await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/projects/set-main-image`,
                  data
                )
                .then((d) => {
                  if (item.slug != null) {
                    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/projects/get-by-id/${item.id}`).then((data) => {
                      console.log(data.data);
                      setMainImage(data.data.image);
                      // item.image = data.data.image;
                    })
                  }
                }).catch((error) => {
                });
              }}>
                <FaCertificate /> {mainImage !== `projects/${item.slug}/images/${img}` ? 'Set as Main' : 'MAIN IMAGE'}
              </Button>
              </>
            </GridItem>
            <GridItem colStart={4} colEnd={6} h='10'>
              <Button colorScheme='red' size='sm' float={'right'} marginTop={'-50px'} position={'relative'} left={'-15px'} onClick={async () => {
                const data = new FormData();
                data.append('path', `projects/${item.slug}/images/${img}`);
                data.append('selectedMenu', 'projects');
                data.append('id', item.id);
                await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/storage/delete-image`,
                  data
                )
                .then((d) => {
                  if (item.slug != null) {
                    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/storage/projects/${item.slug}`).then((data) => {
                      console.log(data.data);
                      setImages(data.data);
                    })
                  }
                }).catch((error) => {
                });
              }}>
                <FaTrash /> Delete
              </Button>
            </GridItem>
          </Grd>
          </>
        )
      })}
      
      </>
      }
    </>
  )
}

export function GeneralForm({item}: any) {
  return (
    <>
      {CreateInput('name', 'text', 'Όνομα', 'Το όνομα της ρύθμισης.', item ? item.name : null, true)}
      <Space value="20px" />
      {item.type == 'text' &&
        <>
          {CreateTextarea('value', 'Επιλογή', 'Η τιμή της ρύθμισης.', item ? item.value : null)}
        </>
      }
      {/* {item.type == 'image' &&
        <>
          {CreateInput('value', 'file', 'Επιλογή', 'Η επιλογή της ρύθμισης.', null)}
        </>
      }
      {'url' in item &&
        <>
          {CreateTextarea('url', 'Τοποθεσία (link)', 'Η τοποθεσία (link) της ρύθμισης.', item ? item.url : null)}
        </>
      }
      {'description' in item &&
        <>
          {CreateTextarea('description', 'Κείμενο', 'Το κείμενο που θα εμφανίζεται.', item ? item.description : null)}
        </>
      } */}
      <Space value="20px" />
    </>
  )
}






















export function PartnersForm({item, uploadImages}: any) {
  const [mainImage, setMainImage]: any = useState(null);
  useEffect(() => {
    if (item != null) {
      if (item.image != null) {
        setMainImage(item.image);
      }
    }
    
  }, []);

  const uploadFunc = async (e: any) => {
    if (item.name != null) {
      await uploadImages(e);
      axios.get(`${process.env.REACT_APP_API_ENDPOINT}/storage/partners/${item.name}`).then((data) => {
        data.data.length == 0 ? setMainImage(null) : setMainImage(data.data[0]);
      })
    }
  }
  return (
    <>
      {CreateInput('name', 'text', 'Όνομα', 'Το όνομα του συνεργάτη.', item ? item.name : null)}
      <Space value="20px" />
      {CreateTextarea('url', 'Τοποθεσία (link)', 'Η τοποθεσία (link).', item ? item.url : null)}
      {item != null &&
      <>
      <Space value="20px" />
      {CreateImageInput('value', 'file', 'Φωτογραφία', 'Η φωτογραφία του συνεργάτη.', null, uploadFunc)}
      {/* <Space value="20px" /> */}
      {/* {CreateCheckbox('active', 'Ενεργό', 'Αν είναι ενεργός ο συνεργάτης.', item ? item.active : false)} */}

      {mainImage &&
        <>
          <Space value="10px" />
          <Image
            src={`${process.env.REACT_APP_API_STORAGE_ENDPOINT}/${mainImage}`}
            width='100%'
          />
          
          <Grd templateColumns='repeat(2, 1fr)' gap={4}>
            <GridItem colStart={4} colEnd={6} h='10'>
              <Button colorScheme='red' size='sm' float={'right'} marginTop={'-50px'} position={'relative'} left={'-15px'} onClick={async () => {
                const data = new FormData();
                data.append('path', `${mainImage}`);
                data.append('selectedMenu', 'partners');
                data.append('id', item.id);
                await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/storage/delete-image`,
                  data
                )
                .then((d) => {
                  if (item.name != null) {
                    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/storage/partners/${item.name}`).then((data) => {
                      console.log(data.data);
                      setMainImage(data.data);
                    })
                  }
                }).catch((error) => {
                });
              }}>
                <FaTrash /> Delete
              </Button>
            </GridItem>
          </Grd>
      
      </>
      }
    </>
    }
    </>
  )
}

function CreateInput(name: string, type: string, label: string, helper: string, value: any, disabled: boolean = false, otherProps: any = null) {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Input type={type} {...otherProps} name={name} id={name} defaultValue={value} disabled={disabled}/>
      <FormHelperText>{helper}</FormHelperText>
    </FormControl>
  )
}

function CreateImageInput(name: string, type: string, label: string, helper: string, value: any, uploadFunction: any, disabled: boolean = false, otherProps: any = null) {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Input type={type} {...otherProps} name={name} id={name} defaultValue={value} disabled={disabled} onChange={(e) => {uploadFunction(e)}}/>
      <FormHelperText>{helper}</FormHelperText>
    </FormControl>
  )
}

function CreateTextarea(name: string, label: string, helper: string, value: any) {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Textarea placeholder="" name={name} id={name} defaultValue={value} minHeight={'200px'}/>
      <FormHelperText>{helper}</FormHelperText>
    </FormControl>
  )
}

function CreateCheckbox(name: string, label: string, helper: string, value: any) {
  return (
    <FormControl>
      <Checkbox defaultIsChecked name={name} id={name} checked={value}>{label}</Checkbox>
    </FormControl>
  )
}

function CreateSelect(name: string, label: string, helper: string, value: any, items: any) {
  console.log(items);
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Select defaultValue={value} name={name} id={name}>
        {items.map((item: any, index: any) => {
          return <option value={item.id}>{item.name}</option>
        })}
      </Select>
      <FormHelperText>{helper}</FormHelperText>
    </FormControl>
  )
}