import { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react"
import React, { useState } from "react"
import { CategoryForm, GeneralForm, PartnersForm, ProjectsForm, SubCategoryForm } from "./Forms/Forms"
// import slugify from 'react-slugify';
import slug from 'slug';
import axios from "axios";
import { resizeFile } from "../../Firebase/dbHelper";

export default function MyModal({open, closeModal, selectedMenu, onGetData, itemEdit, gridData, token}: any) {
  return (
    <>
      <Modal isOpen={open} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{itemEdit ? 'Επεξεργασία εγγραφής' : 'Νέα εγγραφή'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            { selectedMenu == 'categories' &&
              <CategoryForm item={itemEdit ? itemEdit : null} uploadMainImage={uploadMainImage} uploadImages={uploadImages}/>
            }
            { selectedMenu == 'sub-categories' &&
              <SubCategoryForm item={itemEdit ? itemEdit : null} categories={gridData} />
            }
            { selectedMenu == 'projects' &&
              <ProjectsForm item={itemEdit ? itemEdit : null} categories={gridData} uploadImages={uploadImages}/>
            }
            { selectedMenu == 'generals' &&
              <GeneralForm item={itemEdit ? itemEdit : null} subCategories={gridData}/>
            }
            { selectedMenu == 'partners' &&
              <PartnersForm item={itemEdit ? itemEdit : null} uploadImages={uploadMainImageByName}/>
            }
          </ModalBody>
            {/* Put form here to create new item */}
          <ModalFooter>
            <Button colorScheme="green" mr={3} borderRadius={0} onClick={() => { sentData(); }}>Αποθήκευση</Button>
            <Button colorScheme="red" onClick={closeModal} borderRadius={0}>Ακύρωση</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )

  async function uploadMainImage(e: any) {
    if (itemEdit && e.target.files.length > 0) {
      const image: any = await resizeFile(e.target.files[0], selectedMenu == 'general');
      const data = new FormData();
      data.append('image', image);
      data.append('path', `${selectedMenu}/${itemEdit.slug}`);
      data.append('id', itemEdit.id);
      data.append('selectedMenu', selectedMenu);
      // const formData = {file: createImage(image), path: `${selectedMenu}/${itemEdit.slug}/image.jpg`}
      return await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/storage/store-main-image`, 
        data
      )
      .then((d) => {     
        console.log(d.data);
        return d.data;   
      }).catch((error) => {
      });
    }

    return false;
  }

  async function uploadMainImageByName(e: any) {
    if (itemEdit && e.target.files.length > 0) {
      const image: any = await resizeFile(e.target.files[0], selectedMenu == 'general');
      const data = new FormData();
      data.append('image', image);
      data.append('path', `${selectedMenu}/${itemEdit.name}`);
      data.append('id', itemEdit.id);
      data.append('selectedMenu', selectedMenu);
      // const formData = {file: createImage(image), path: `${selectedMenu}/${itemEdit.slug}/image.jpg`}
      return await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/storage/store-main-image`, 
        data
      )
      .then((d) => {     
        console.log(d.data);
        return d.data;   
      }).catch((error) => {
      });
    }

    return false;
  }

  async function uploadImages(e: any) {
    if (itemEdit && e.target.files.length > 0) {
      let images: any = [];
      const data = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        let file = e.target.files.item(i);
        
        let image: any = await resizeFile(file, selectedMenu == 'general');
        // images.push(image);
        data.append('images[]', image);
      }

      // console.log(images);
      
      // data.append('images', e.target.files);
      // data.append('images', images);
      data.append('path', `${selectedMenu}/${itemEdit.slug}/images/`);
      data.append('id', itemEdit.id);
      data.append('selectedMenu', selectedMenu);
      data.append('token', token);
      // const formData = {file: createImage(image), path: `${selectedMenu}/${itemEdit.slug}/image.jpg`}
      await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/storage/store-images`, 
        data
      )
      .then((d) => {
        console.log(d);
        return d.data;
      }).catch((error) => {
      });
    }

    return false;
  }

  async function sentData() {
    var data: any = {};
    var randomName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    //var file = (document.getElementById('value') as HTMLFormElement)['files'];
    // var files = document.getElementById("values") != null ? null : (document.getElementById('values') as HTMLFormElement)['files'];
    // if (selectedMenu == 'categories') {
    data['token'] = token;
    data['name'] = (document.getElementById('name') as HTMLFormElement)['value'];

    if (selectedMenu == 'generals') {
      if (itemEdit.type == 'text') {
        data['value'] = (document.getElementById('value') as HTMLFormElement)['value'];
      } else if (itemEdit.type == 'image') {
        data['value'] = `general/${itemEdit.key}.jpg`;
      }
      
      if ('url' in itemEdit) {
        // data['url'] = (document.getElementById('url') as HTMLFormElement)['value'];
      }
      if ('description' in itemEdit) {
        // data['description'] = (document.getElementById('description') as HTMLFormElement)['value'];
      }
    } else if (selectedMenu == 'partners') {
      data['url'] = (document.getElementById('url') as HTMLFormElement)['value'];
      // data['active'] = (document.getElementById('active') as HTMLFormElement).checked;
      // if (file.length > 0) {
      //   data['image'] = `${selectedMenu}/${randomName}.${file[0].name.split('.').pop()}`;
      // }
    } else {
      data['description'] = (document.getElementById('description') as HTMLFormElement)['value'];
      // data['active'] = (document.getElementById('active') as HTMLFormElement).checked;
      data['slug'] = itemEdit ? itemEdit.slug : slug((document.getElementById('name') as HTMLFormElement)['value']);
      // data['image'] = `${selectedMenu}/${data['slug']}/image.jpg`;
      data['priority'] = document.getElementById("priority") == null ? null : (document.getElementById('priority') as HTMLFormElement)['value'];
    }

    if (selectedMenu == 'projects') {
      data['categoryId'] = (document.getElementById('categoryId') as HTMLFormElement)['value'];
    }
    // } else if (selectedMenu == 'sub-categories') {
    //   data['name'] = (document.getElementById('name') as HTMLFormElement)['value'];
    //   data['description'] = (document.getElementById('description') as HTMLFormElement)['value'];
    //   data['active'] = (document.getElementById('active') as HTMLFormElement).checked;
    //   data['slug'] = itemEdit ? itemEdit.slug : slug((document.getElementById('name') as HTMLFormElement)['value']);
    //   data['image'] = `${selectedMenu}/${data['slug']}/image.jpg`;    
    // }
    if (itemEdit) {
      await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/${selectedMenu}/${itemEdit.id}`, {
        data: data
      })
      .then((d) => {        
      }).catch((error) => {
      });
    } else {
      await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/${selectedMenu}`, {
        data: data
      })
      .then((d) => {        
      }).catch((error) => {
      });
    }

    // if (file.length > 0) {
    //   try {
    //     const image: any = await resizeFile(file[0], selectedMenu == 'general');

    //     if (selectedMenu == 'general') {
    //       storage.ref(`${selectedMenu}/${itemEdit.key}.jpg`).put(image);
    //     } else if (selectedMenu == 'partners') {
    //       storage.ref(`${selectedMenu}/${randomName}.${file[0].name.split('.').pop()}`).put(image);
    //     } else {
    //       storage.ref(`${selectedMenu}/${data['slug']}/image.jpg`).put(image);
    //     }
        
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }

    closeModal();
    onGetData(selectedMenu);

    console.log(data);
  }
}