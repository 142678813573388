import { Box, Button, FormControl, FormHelperText, FormLabel, Input } from '@chakra-ui/react';
import axios from 'axios';
import React, { useState } from 'react';
import Image from "react-graceful-image";

async function loginUser(credentials: any, setToken: any) {
  const data = new FormData();
  data.append('email', credentials.email);
  data.append('password', credentials.password);
  return axios.post(`${process.env.REACT_APP_API_ENDPOINT}/user/login`, 
        data
      )
      .then((d) => {
        console.log(d.data);
        if (d.data) {
          setToken(d.data);
        } else {
          alert('Λάθος συνδιασμός, παρακαλώ προσπαθήστε ξανά.')
        }
      }).catch((error) => {
      });
}

export default function Login({setToken}: any) {
  const [email, setEmail]: any = useState();
  const [password, setPassword]: any = useState();
  return(
    <>
      <Image
        src={`${process.env.PUBLIC_URL}/logo-black.svg`}
        width='64px'
        height='64px'
        style={{margin: '30px auto'}}
      />
      <Box margin={'40px auto'} w={'100%'}>
      
        <Box margin={'0 auto'} textAlign={'center'} w={'fit-content'} borderWidth='1px' borderRadius='lg' p={'6'}>
          <h1>Please Log In</h1>
          <form>
            <FormControl>
              <FormLabel htmlFor='email'>Email address</FormLabel>
              <Input id='email' type='email' onChange={(e) => {setEmail(e.target.value)}} />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='password'>Password</FormLabel>
              <Input id='password' type='password' onChange={(e) => {setPassword(e.target.value)}} />
            </FormControl>
            <Button colorScheme='teal' size='md' type="submit" onClick={(e) => {e.preventDefault(); loginUser({email, password}, setToken)}} marginTop={'4'}>
              Login
            </Button>
          </form>
        </Box>
      </Box>
    </>
  )
}