import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import { getImage } from "../Firebase/dbHelper";
import Quote from "./Quote";
import Colors from "../static/Colors";
import Space from "./Helpers/Space";
import Image from "react-graceful-image";

type Props = {image: any, text: string, height: string, description?: string};
type State = {imageUrl: any, loading: boolean};
export default class BottomTextCard extends React.Component <Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      imageUrl: null,
      loading: true
    }
  }

  public async componentDidMount() {
    let url = await getImage(this.props.image);
    this.setState({
      imageUrl: url,
      loading: false
    });
    // console.log(url);
  }

  public render() {
    return (
      !this.state.loading &&
      <Box width={'100%'} margin="5px 5px 30px">
        <Box position="relative" width="100%" height={this.props.height} overflow="hidden">
          <Image
            src={this.state.imageUrl || null}
            noLazyLoading={true}
            width='100%'
            className="zoom-hover grid-img"
            style={{objectPosition: '0 top'}}
          />
        </Box>
        <Heading as="h2" paddingLeft="20px" textAlign="left" className="noto-font" fontSize="24px" marginTop={'15px'} fontWeight={'400'}>{this.props.text}</Heading>
        { this.props.description &&
          <>
            <Space value="20px" />
            <Box>
              <Quote position={'left'} borderRight={`0`} borderLeft={`0`} paddingLeft="20px" paddingRight="20px" text={this.props.description}/>
            </Box>
            <Space />
          </>
        }
      </Box>
    );
  }
}