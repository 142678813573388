import { Box, Container, Heading, HStack, Link, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import Colors from "../static/Colors";
import Space from "./Helpers/Space";
import { FaEnvelope, FaFacebook, FaInstagramSquare } from "react-icons/fa";
import GeneralSetting from "./Helpers/GeneralSetting";
import Image from "react-graceful-image";

type Props = {general: any}
type State = {}
export default class Footer extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    const facebook: any = GeneralSetting.value(this.props.general, 'facebook-link');
    const instagram: any = GeneralSetting.value(this.props.general, 'instagram-link');
    const email: any = GeneralSetting.value(this.props.general, 'email');

    let f = facebook.find((a: any) => a != undefined);
    let i = instagram.find((a: any) => a != undefined);
    let e = email.find((a: any) => a != undefined);
    return (
      <>
        <Space />
        <Box width={`100%`} backgroundColor={Colors.DARK}>
          <Container maxW="container.xl" overflow={'auto'} height="auto"  borderTop={`4px solid ${Colors.PRIMARY}`}>
            <Box display="grid" alignItems="center" width="100%" color={Colors.WHITE} justifyContent="center" gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))" gridGap="1rem" margin={'40px auto'}>
              <Box width={'100%'} margin="5px 5px 30px" height={'150px'} textAlign="left">
                <Text fontSize="sm" fontWeight="600" textAlign="left">{`Σχετικά με εμάς`}</Text>
                <Box paddingRight={'20px'} maxW={'250px'} marginTop={'20px'} display={'block'}>
                  <Text fontSize="sm" textAlign="left" fontWeight="200">
                    {GeneralSetting.value(this.props.general, 'footer-description')}
                  </Text>
                </Box>
              </Box>
              <Box width={'100%'} margin="5px auto 30px auto" height={'150px'} textAlign={['left', 'left', 'left', 'left', 'center']}>
                <Text fontSize="sm" fontWeight="600" textAlign={['left', 'left', 'left', 'left', 'center']}>{`Τοποθεσία`}</Text>
                <Box paddingRight={'0px'} marginTop={'20px'}  display={'block'} wordBreak="break-word">
                  <Text fontSize="sm" textAlign={['left', 'left', 'left', 'left', 'center']} fontWeight="200">
                    {GeneralSetting.value(this.props.general, 'address')}
                  </Text>
                </Box>
              </Box>
              {/* <Box width={'100%'} margin="5px 5px 30px" height={'150px'} textAlign={['left', 'left', 'left', 'left', 'right']}>
                <Text fontSize="sm" fontWeight="600">{`Επικοινωνία`}</Text>
                <Box marginTop={'20px'} display={'block'}>
                  <Link href={`mailto:${e}`} isExternal><Text fontSize="sm" fontWeight="200">{email}</Text></Link>
                </Box>
                <Box marginTop={'10px'} display={'block'}>
                  <Text fontSize="sm" fontWeight="200">
                    {GeneralSetting.value(this.props.general, 'phone')}
                  </Text>
                </Box>
              </Box> */}
              <Box width={'100%'} margin="5px 5px 30px" height={'150px'} textAlign={['left', 'left', 'left', 'left', 'right']}>
                <Text fontSize="sm" fontWeight="600">{`Ακολουθήστε μας`}</Text>
                <Box marginTop={'20px'} display={'block'}>
                  <HStack spacing="24px" justifyContent={['left', 'left', 'left', 'left', 'right']} margin="0 auto 0 auto">
                    <Link href={f} isExternal><FaFacebook /></Link>
                    <Link href={i} isExternal><FaInstagramSquare /></Link>
                  </HStack>
                </Box>
              </Box>
            </Box>
            <Box display="block" overflow="auto" color={Colors.GREY} margin={'50px 0 15px 0'}>
              <SimpleGrid columns={[2]}>
                <Box>
                  
                  <Text fontSize="sm" textAlign="left" fontWeight="200">
                    <Image
                    src={`${process.env.PUBLIC_URL}/logo-white.svg`}
                    width='32px'
                    height='32px'
                    style={{display: 'inline-block', marginRight: '10px'}}
                    />© Copyright {new Date().getFullYear()} | Vikatos Shading</Text>
                </Box>
                <Box>
                  <Text fontSize="sm" textAlign="right" fontWeight="200">
                    <a href="https://hellofoxweb.com">
                      <Image
                      src={`${process.env.PUBLIC_URL}/hellofoxweblogo.png`}
                      width='16px'
                      style={{display: 'inline-block', marginRight: '10px'}}
                      />
                      
                      Φτιάχτηκε από την Hello Fox Web 
                    
                    </a>
                  </Text>
                </Box>
              </SimpleGrid>
              
            </Box>
            
            {/* <Box color={Colors.GREY} margin={'40px auto'} width={'100%'} textAlign={'center'}>
              <Text fontWeight={'700'}  fontSize="lg">Τοποθεσία</Text>
            </Box>
            <Box color={Colors.GREY} margin={'40px auto'} width={'100%'} textAlign={'center'}>
              <Image
                src={`${process.env.PUBLIC_URL}/logo-white.svg`}
                width='64'
                height='64'
                style={{margin: '40px auto'}}
              />
            </Box>
            <Box color={Colors.GREY} margin={'40px auto'} width={'100%'} textAlign={'center'}>
              <HStack spacing="24px" justifyContent="center" margin="0 auto 0 auto">
                <FaFacebook />
                <FaInstagramSquare />
                <FaEnvelope />
              </HStack>
            </Box>
            <Box color={Colors.GREY} margin={'40px auto'} width={'100%'} textAlign={'center'}>
              <Text fontSize="md">Τηλέφωνο Επικοινωνίας: 6979999999</Text>
              <Text fontSize="md">Οδός: Οδός 25, Αιγάλεω, 12243</Text>
            </Box>

            <Box color={Colors.GREY} margin={'40px auto'} width={'100%'} textAlign={'center'}>
              <Text fontSize="xs">© Copyright {new Date().getFullYear()} | Vikatos Shading</Text>
            </Box> */}
          </Container>
        </Box>
      </>
    )
  }
}