import React from "react";
import axios from "axios";
import { Box } from "@chakra-ui/react";
import Gallery from 'react-grid-gallery';
import { getImage } from "../Firebase/dbHelper";

type Props = {path: any};
type State = {images: any};

export default class ImageGallery extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      images: null
    };
  }

  componentDidMount(): void {
    console.log(`${process.env.REACT_APP_API_ENDPOINT}/storage/${this.props.path}`);
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/storage/${this.props.path}`)
      .then(async (data) => {
        let images: any = [];
        if (data.data.length > 0) {
          for (const d in data.data) {
            let i = data.data[d];
            let path = await getImage(`${this.props.path}/images/${i}`);
            images.push({
              src: path,
              thumbnail: path,
              thumbnailWidth: 500,
              thumbnailHeight: 600,
              thumbnailStyle: {
                maxWidth: '296px !important'
              }
            });
          }
        }
        console.log(images);
        this.setState({
          images: images
        })
      }).catch((error) => {
        console.error(error);
      });
  }

  render(): React.ReactNode {
    return (
      <>
        {this.state.images &&
          <Box width="100%">
            <Gallery images={this.state.images} height="100%" enableImageSelection={false} backdropClosesModal={true} />
          </Box>
        }
      </>
    )
  }
}