import { Box, Heading } from "@chakra-ui/react";
import React from "react";
import BottomTextCard from "./BottomTextCard";
import Space from "./Helpers/Space";
import {
  NavLink
} from "react-router-dom";

type Props = {projects: any}
type State = {}
export default class Projects extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      this.props.projects &&
      <>
        <Heading as="h2" className="heading-dot" fontSize={'56px'} margin={'0px auto'}>Οι δουλειές μας</Heading>
        <Space />
        <Box display="grid" alignItems="center" gridTemplateColumns="repeat(auto-fit, minmax(350px, 1fr))" gridGap="1rem">
          {this.props.projects.map((item: any, index: any) => {
            return (
              <NavLink exact={true} to={`/projects/${item.slug}`} key={index} style={{height: '100%'}}>
                <BottomTextCard image={item.image} height={"500px"} text={item.name} />
              </NavLink>
            )
          })}
        </Box>
      </>
    )
  }

}