import * as React from "react"
import {
  ChakraProvider,
  extendTheme
} from "@chakra-ui/react"
import Header from "./components/Header"
import './static/style.css'
import HomePage from "./components/HomePage"
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import CategoriesPage from "./components/CategoriesPage"
import ItemPage from "./components/ItemPage"
import ProjectsPage from "./components/ProjectsPage"
import AdminPage from "./components/Admin/AdminPage"
import Footer from "./components/Footer"
import { FaSpinner } from "react-icons/fa"
import About from "./components/About"
import axios from 'axios';
import Login from './components/Admin/Login';

type Props = {};
type State = {categories: any, loading: boolean, general: any, partners: any, token: any};
export default class App extends React.Component<Props, State> {
  myRef: any;
  imagesLoaded: any;
  constructor(props: any) {
    super(props);

    this.state = {
      categories: null,
      loading: true,
      general: null,
      partners: null,
      token: null
    }

    this.setToken = this.setToken.bind(this);
  }

  public setToken(token: any) {
    console.log(token);
    this.setState({
      token: token
    })
  }

  public componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/categories`)
    // axios.get(`http://localhost/vikatosshading.gr/public/api/categories`)
    .then((data) => {
      // console.log(data);
      this.setState({
        categories: data.data
      })
    }).catch((error) => {
      console.error(error);
    });

    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/generals`)
    // axios.get(`http://localhost/vikatosshading.gr/public/api/generals`)
    .then((data) => {
      this.setState({
        general: data.data
      })
    }).catch((error) => {
      console.error(error);
    });

    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/partners`)
    // axios.get(`http://localhost/vikatosshading.gr/public/api/partners`)
    .then((data) => {
      this.setState({
        partners: data.data
      })
    }).catch((error) => {
      console.error(error);
    });

    this.setState({
      loading: false
    })
  }

  public render() {
    return (
    <ChakraProvider theme={extendTheme({config:{
      useSystemColorMode: false,
      initialColorMode: "light"}
      })}>
        <React.Suspense fallback={<FaSpinner />}>
        { !this.state.loading && this.state.general && this.state.categories &&
        <Router>
          <Switch>
            <Route exact path="/">
              <Header categories={this.state.categories} general={this.state.general}/>
              {/* {this.state.partners && */}
                <HomePage categories={this.state.categories} general={this.state.general} partners={this.state.partners}/>
              {/* } */}
            </Route>
            <Route exact path="/categories/:category?" render={({match}: any) => (
              <>
              <Header categories={this.state.categories} general={this.state.general}/>
              <CategoriesPage params={match.params} key={window.location.pathname} general={this.state.general}/>
              </>
            )}>
            </Route>
            <Route exact path="/categories/:category/:item?" render={({match}: any) => (
              <>
                <Header categories={this.state.categories} general={this.state.general}/>
                <ItemPage params={match.params} key={window.location.pathname} collection={"sub-categories"} general={this.state.general}/>
              </>
            )}>
            </Route>
            <Route exact path="/projects">
              <Header categories={this.state.categories} general={this.state.general}/>
              <ProjectsPage key={window.location.pathname} general={this.state.general}/>
            </Route>
            <Route exact path="/projects/:item?" render={({match}: any) => (
              <>
                <Header categories={this.state.categories} general={this.state.general}/>
                <ItemPage params={match.params} key={window.location.pathname} collection={"projects"} general={this.state.general}/>
                </>
              )}>
            </Route>
            <Route exact path="/about">
              <Header categories={this.state.categories} general={this.state.general}/>
              <About general={this.state.general}/>
            </Route>
            <Route exact path="/admin">
              {this.state.token ? <AdminPage token={this.state.token} /> : <Login setToken={this.setToken} />}
            </Route>
          </Switch>
        </Router>
      }
      </React.Suspense>
    </ChakraProvider>
    )
  }
}
