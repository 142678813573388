import React from "react";
import { Box, Container, Text } from "@chakra-ui/react";
import {
  NavLink
} from "react-router-dom";
import SignIn from "./SignIn";
import SignOut from "./SignOut";
import Space from "../Helpers/Space";
import Grid from "./Grid";

type Props = {token: any}
type State = {user: any}
export default class AdminPage extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      user: null
    }
  }

  public componentDidMount() {
    // auth.onAuthStateChanged(userAuth => {
    //   // console.log(userAuth);
    //   this.setState({ user: userAuth });
    // });
  };
  
  public render() {
    return (
      <>
      <Space value="15px"/>
      <Container maxW="container.xl" textAlign={'center'}>
      <Grid token={this.props.token}/>
      </Container>
      </>
    );
  }
}