import { Container, Skeleton, Stack } from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { Breadcrumbs } from "./Breadcrumbs";
import Footer from "./Footer";
import Space from "./Helpers/Space";
import Projects from "./Projects";
import Social from "./Social";

type Props = {general: any};
type State = {projects: any, loading: boolean};
export default class ProjectsPage extends React.Component<Props, State> {

  constructor(props: any) {
    super(props);

    this.state = {
      projects: null,
      loading: true
    };

    this.getProjects = this.getProjects.bind(this);
  }

  public async componentDidMount() {
    await this.getProjects();
  }

  public async getProjects() {

    this.setState({
      loading: true
    });

    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/projects`)
    .then((data) => {
      this.setState({
        projects: data.data,
        loading: false
      })
    }).catch((error) => {
      console.error(error);
    });
  
    //   let collection = "projects";
    //   const projects = firestore.collection(collection).where('active', '==', true);
    //   projects.get().then(snapshot => {
    //     this.setState({
    //       projects: snapshot.docs,
    //       loading: false
    //     });
    //   });
    // } catch (ex) {
    //   console.log(ex);
    // }
  }

  public render() {
    return (
      <main>
        <Container maxW="container.xl" textAlign={'center'}>
          <Breadcrumbs parent={this.state.projects} item={null} />
          <Space />
          {
            !this.state.loading ?
            this.state.projects &&
              <>
                <Projects projects={this.state.projects} />
                <Space />
                <Social general={this.props.general} />
              </>
            :
            <>
              <Space />
              <Stack>
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </Stack>
            </>
          }
        </Container>
        <Footer general={this.props.general}/>
      </main>
    )
  }
}