import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import Space from "./Helpers/Space";

type Props = { position: string, borderLeft: string, borderRight: string, paddingLeft: string, paddingRight: string, text: any };
type State = {text: any};
export default class Quote extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      text: null
    }
  }
  componentDidMount() {
    let isArray = Array.isArray(this.props.text);
    let t = isArray ? this.props.text.filter((a:any) => {return (a != undefined)}) : this.props.text;
    if (t) {
      this.setState({
        text: isArray ? t[0].replace(/(?:\\r)?\\n/g, '\n') : t.replace(/(?:\\r)?\\n/g, '\n')
      })
    }
    
  }
  render() {
    return (
      <>
      <Flex alignItems={this.props.position} justifyContent={this.props.position}>
        {/* width was `100%`, `100%`, `100%`, `70%` */}
        <Box borderLeft={`${this.props.borderLeft}`} style={{whiteSpace: 'pre-line'}} borderRight={`${this.props.borderRight}`} width={[`100%`, `100%`, `100%`, `100%`]} padding={[`0 10px`, `0 10px`, `0 10px`, `0 ${this.props.paddingRight} 0 ${this.props.paddingLeft}`]}>
          <Text as="h2" textAlign="justify" fontSize="24px" fontWeight={'300'}>
            {this.state.text}
          </Text>
        </Box>
      </Flex>
      </>
    );
  }
}