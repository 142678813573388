export default class Translation {
  public static PROJECTS = 'Οι δουλειές μας';
  public static CATEGORIES = 'Κατηγορίες';
  public static SUBCATEGORIES = 'Υπο-Κατηγορίες';
  public static PARTNERS = 'Συνεργάτες';
  public static GENERAL = 'Γενικά';
  public static HOME = 'Αρχική';
  public static WORDS = {
    'categories': 'Κατηγορίες',
  }
}