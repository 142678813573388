import { Box, Heading, HStack, Link } from "@chakra-ui/react";
import React from "react";
import { FaFacebookF, FaInstagram, FaRegEnvelope } from "react-icons/fa";
import Space from "./Helpers/Space";
import Colors from "../static/Colors";
import GeneralSetting from "./Helpers/GeneralSetting";
import Image from "react-graceful-image";

type Props = {general: any}
type State = {}
export default class Social extends React.Component <Props, State> {
  public render() {
    const facebook: any = GeneralSetting.value(this.props.general, 'facebook-link');
    const instagram: any = GeneralSetting.value(this.props.general, 'instagram-link');
    const email: any = GeneralSetting.value(this.props.general, 'email');

    let f = facebook.find((a: any) => a != undefined);
    let i = instagram.find((a: any) => a != undefined);
    let e = email.find((a: any) => a != undefined);
    return (
      <>
      <Box bgColor={Colors.TRANSPARENT} w={`100%`} color={Colors.DARK} padding="30px 0">
        <Box borderRadius="50%" bgColor={Colors.DARK} padding="30px" width="160px" height="160px" margin="0px auto 20px auto">
          <Image
            src={`${process.env.PUBLIC_URL}/logo-white.svg`}
          />
        </Box>
        <Heading as="h2" textAlign="center" fontSize="24px" fontWeight={'700'}>Βρείτε μας</Heading>
        <Space value="20px" />
        <HStack spacing="24px" justifyContent="center" margin="0 auto 0 auto">
          <Link href={f} isExternal><FaFacebookF /></Link>
          <Link href={i} isExternal><FaInstagram /></Link>
        </HStack>
        <Space value="20px"/>
        <Link href={`mailto:${e}`} isExternal>{email}</Link>
        </Box>
      </>
    )
  }
}